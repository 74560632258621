import React from 'react'
import styled from 'styled-components'
import Button from './buttons/button'
import Colors from '../buttons/Colors'

const Container = styled.div`
    width: 100%;
    text-align: center;

    button{
        display: block;
    }

    #notes{
        display: block;
        margin: 0 auto;
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
`

export const Time = () => {
    return (
        <Container>
            <h1>TIME</h1>
            <Button color="black" title="CLOCK IN" />
            <textarea name="" id="notes" cols="30" rows="10"></textarea>
            <Button color="black" title="SUBMIT"/>
            <Colors />
        </Container>
    )
}
