import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 70%;
    margin: 0 auto;
    margin-top: 2rem;
    border-radius: 4rem;
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid var(--black);

`


const Ticket = ({ title, due, status}) => {
    return(
        <Container >
            <h5>{title}</h5>
            <h5>{due}</h5>
            <h5>{status}</h5>
        </Container>
    )
}

export default Ticket