import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"

const Container = styled.div`
    width: 70%;
    text-align: center;
    margin: 0 auto;
    letter-spacing: var(--letter-spacing-text);

    p{
        font-size: 2rem;
    }

    h3{
        margin-top: 10rem;
    }

    @media (max-width: 768px) {

      .title{
        font-size: 3rem !important;
      }

      width: 80%;
      margin: 0 auto;
    }
`

export const Announcements = () => {
    const data = useStaticQuery(graphql`
    query announcment {
        notion(title: {eq: "Announcements"}) {
            id
            properties {
              Details {
                value
              }
              Other_Title {
                value
              }
            }
          }
        }
    `)

    const { properties } = data.notion;
    let title = properties.Other_Title.value;
    let details = properties.Details.value;

    return(
        <Container>
            <h1 className='title'>ANNOUNCEMENTS</h1>
            <h3>{title}</h3>
            <p>{details}</p>
        </Container>
    )
}