import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Container = styled.div`
    text-align: center;

    .content-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
        margin: 0 auto;
        grid-gap: 2rem;
        margin-top: 2rem;
    }

    .block{
        background: var(--black);
        padding: 2rem;
        color: var(--white);

        h3{
            margin: 0 auto;
        }
    }

    .tutorials{

    }

    @media (max-width: 768px) {
        .title{
            font-size: 3rem !important;
        }
    }
`

export const Resources = () => {
    const [isShowingTutorials, setIsShowingTutorials] = useState(false);

    return(
        <Container>
            <h1 className='title'>RESOURCES</h1>
            <div className="content-container">
                <Link to="/form-web-app">
                    <div className="block">
                        <h3>FORMS</h3>
                    </div>
                </Link>
                <div className="block">
                    <h3>CONTRACTS</h3>
                </div>
                <div className="block" onClick={() => setIsShowingTutorials(true)}>
                    <h3>TUTORIALS</h3>
                </div>
                <a href="https://rivalschool.slack.com/" target='blank'>
                    <div className="block">
                        <h3>TOOLS</h3>
                    </div>
                </a>
            </div>
        </Container>
    )
}