import React, { useState, useEffect, useContext } from 'react'
import Layout from "../components/layout"
import { Home } from '../components/team/home';
import { Time } from '../components/team/time';
import { Status } from '../components/team/status';
import { Tickets } from '../components/team/tickets';
import { Projects } from '../components/team/projects';
import { Navigation } from '../components/team/navigation';
import { Announcements } from '../components/team/announcements';
import { Resources } from '../components/team/resources';
import netlifyIdentity from "netlify-identity-widget";
import AuthContext from '../store/authContext';
import styled from 'styled-components';
import { navigate } from 'gatsby';

const Container = styled.div`

`

const ButtonContainer = styled.div`
    margin: 0 auto;
    margin-top: 5rem;
    width: 100%;
    text-align: center;

    button{
        box-sizing: border-box;
        display: block;
        width: 20%;
        min-width: 200px;
        height: 50px;
        line-height: 45px;
        margin: 0 auto;
        border: 1px solid var(--white);
        font-family: var(--main-font-light);
        text-transform: uppercase;
        letter-spacing: var(--letter-spacing-button);
        text-align: center;
        font-size: auto;
        background: var(--black);
        color: var(--white);

        &:hover{
            cursor: pointer;
        }
    }
`

const Team = () => {
    const [navItem, setNavItem] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [user, setUser] = useState({})

    useEffect(() => {
        if(localStorage.getItem('rivalschool')){
            setUser(JSON.parse(localStorage.getItem('rivalschool')).user)
            setIsAuthorized(JSON.parse(localStorage.getItem('rivalschool')).isAuthorized)
        }
    }, [])


    const logout = () => {
        netlifyIdentity.logout();
        navigate('/');
    }

    if(isAuthorized){
    return(
        <Layout>
            <Container>
                <Navigation selectedNavItem={(e) => setNavItem(e)} />
                { navItem === '' &&
                    <Home />
                }

                { navItem === 'TIME' &&
                    <Time />
                }

                { navItem === 'STATUS' &&
                    <Status />
                }

                { navItem === 'TICKETS' &&
                    <Tickets />
                }

                { navItem === 'ANNOUNCEMENTS' &&
                    <Announcements />
                }

                { navItem === 'PROJECTS' &&
                    <Projects />
                }

                { navItem === 'RESOURCES' &&
                    <Resources />
                }

                <ButtonContainer>
                    <button style={{position: 'absolute', bottom: '-100px', left: '0', right: '0', }} onClick={() => logout()}>Log Out</button>
                </ButtonContainer>
            </Container>
        </Layout>
        )
    }else{
        return(
           <h1>Page Not Found</h1>
        )
    }
}

export default Team;