import React from 'react'
import styled from 'styled-components'
import Button from './buttons/button'
import Ticket from './block/ticket'
import Colors from '../buttons/Colors'

const Container = styled.div`
    width: 100%;
    text-align: center;
`

export const Tickets = () => {
    const STATUS = {
        1: 'In Progress',
        2: 'New',
        3: 'Complete',
        4: 'Not Started'
    }
    const tickets = [
        {
            title: 'Fix Margins',
            due: '12/15',
            assigned: 'swellls',
            status: STATUS[1]
        },
        {
            title: 'Fix NPM Package',
            due: '1/10',
            assigned: 'deaton',
            status: STATUS[4]
        },
        {
            title: 'Update Docker Image',
            due: '2/04',
            assigned: 'amoreno',
            status: STATUS[3]
        }
    ]

    return (
        <Container>
            <h1>TICKETS</h1>
            <Button title="CREATE TICKET" color="black"/>
            {tickets.map(ticket => {
                return(<Ticket key={ticket.title} title={ticket.title} due={ticket.due} status={ticket.status}/>)
            })}
            <Colors />
        </Container>
    )
}
