import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    text-align: center;
`

export const Projects = () => {
    return(
        <Container>
            <h1>COMING SOON...</h1>
        </Container>
    )
}