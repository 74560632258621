import React from 'react'
import styled from 'styled-components'
import Colors from '../buttons/Colors'
import Button from './buttons/button'
import { useStaticQuery, graphql } from 'gatsby'

const Container = styled.div`
    width: 100%;

    .tv-outside{
        width: 80%;
        height: 80vh;
        border: 20px solid var(--black);
        margin: 0 auto;
        text-align: center;
        padding-top: 20px;
    }

    .tv-inside{
        width: 80%;
        height: 80%;
        border: 20px solid var(--black);
        margin: 0 auto;

        h1{
            letter-spacing: var(--letter-spacing-main);
            margin-top: 10%;
        }
    }

    .buttons{
        text-align: center;
        margin-top: 2rem;
    }

    .buttons > button{
        margin-left: 2rem;
        margin-right: 2rem;
    }


    @media (max-width: 768px) {
        .tv-outside{
        width: 100%;
        border: 0px solid var(--black);
    }

    .tv-inside{
        width: 100%;
        height: 100%;
        border: 0px solid var(--black);
        margin: 0 auto;
    }
`

export const Home = () => {
    const data = useStaticQuery(graphql`
    query {
        notion(title: {eq: "Video"}) {
          id
          title
          properties {
            Link {
              value
            }
          }
        }
      }
      `)

      const videoLink = data.notion.properties.Link.value;

    return (
        <Container>
            <div className="tv-outside">
                <div className="tv-inside">
                    {!videoLink &&
                        <h1>TIGER TV!</h1>
                    }

                    {videoLink &&
                        <iframe id="ytplayer" type="text/html" width="100%" height="100%" src={videoLink} frameBorder="0"></iframe>
                    }
                </div>
                <Colors />
            </div>
        </Container>
    )
}
