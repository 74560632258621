import React from 'react'
import styled from 'styled-components'
// import { logout } from '../../utils/auth'
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const Container = styled.div`
    width: 100%;
    text-align: center;

    .img-container{
        width: 40px;
        position: relative;
        left: 10px;
        top: 10px;
    }

    ul{
        display: inline-flex;
        list-style-type: none;
    }

    li{
        text-decoration: underline;
        margin-left: 2rem;
        margin-right: 2rem;

        &:hover{
            cursor: pointer;
            text-decoration: line-through;
        }
    }

    @media (max-width: 768px) {
        li{
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }

`

export const Navigation = ({ selectedNavItem }) => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "white-tiger.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


    const navItems = ['ANNOUNCEMENTS', 'RESOURCES', 'STATUS', 'PROJECTS']

    const selectedItem = (item) => {
        selectedNavItem(item)
    }

    return (
        <Container>
            <div className="img-container">
                <a href="/team"><Img className='logo' fluid={data.placeholderImage.childImageSharp.fluid} /></a>
            </div>
            <ul>
                {
                    navItems.map(item => {
                        return(<li key={item} onClick={() => selectedItem(item)}>{item}</li>)
                    })
                }
                <a href="https://rivalschool.atlassian.net/jira/projects" target="blank"><li>TICKETS</li></a>
                {/* <li>Yo {localStorage.name}!</li> */}
                {/* <li onClick={() => logout()}>LOG OUT</li> */}
            </ul>
        </Container>
    )
}
